import { type UniversalCategory } from '@/features/shop/services/UniversalCategories/types'
import { routes } from '@shared/constants/routes'
// This JSON file is updated at build time (See: /scripts/build_data/copy_universal_categories.mjs)
// This data is used to set the initial state for categories and featured categories.
// There is no client-side fetching for universal categories.
import categoryPayload from '@/__data__/universal-categories.json'
import { ungatedCategoryMetaDescriptions } from '@/__data__/ungated-category-meta-descriptions'

export const getUniversalCategories = (): UniversalCategory[] => {
  return categoryPayload.category
}

export const getUniversalCategory = (slug: string[]) => {
  const universalCategories = getUniversalCategories()
  if (slug.length) return findCategoryBySlug(universalCategories, slug)
}

export const findCategoryById = (
  categories: UniversalCategory[],
  id: number
): UniversalCategory | undefined => {
  if (!id) return
  for (const category of categories) {
    if (category.id === id) return category
    if (category.children) {
      const subcategory = findCategoryById(category.children, id)
      if (subcategory) return subcategory
    }
  }
}

/**
 * @param  {UniversalCategory[]} categories - An array of universal categories
 * @param  {string[]} slug - An array of slugs in hierarchical order (e.g. `['snacks', 'crackers', 'cheese-crackers']`)
 */
export const findCategoryBySlug = (
  categories: UniversalCategory[],
  slug: string[]
): UniversalCategory | undefined => {
  if (!slug.length) return

  for (const category of categories) {
    if (category.url_slug === slug[0]) {
      // return category if there is only one slug
      if (slug.length === 1) return category

      if (category.children) {
        // remove first slug without mutating the original slug object
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_firstItem, ...remainingSlug] = slug
        return findCategoryBySlug(category.children, remainingSlug)
      }
    }
  }
}

export const urlBuilder = (
  universalCategories: UniversalCategory[],
  category: UniversalCategory | undefined,
  zip?: string
) => {
  const slugs: Array<string> = [routes.UNGATED_CATEGORY.url]
  const ids = [...(category?.ancestors || []), category?.id]
  ids.forEach((id) => {
    const _category = findCategoryById(universalCategories, id ?? 0)
    if (_category) slugs.push(String(_category.url_slug))
  })

  return slugs.join('/') + (zip ? `?zip=${zip}` : '')
}
export const getParentCategory = (currentCategory?: UniversalCategory) => {
  const universalCategories = getUniversalCategories()
  return findCategoryById(universalCategories, currentCategory?.parent_id ?? 0)
}

export const getCategoryMetaDescription = (
  pageCategory: UniversalCategory | undefined
) => {
  if (pageCategory) {
    const path = urlBuilder(getUniversalCategories(), pageCategory)
    const metaDescription = ungatedCategoryMetaDescriptions.find(
      (category) => category.path === path
    )?.description
    if (metaDescription) {
      return metaDescription
    }
  }
  return `Get your favorite ${pageCategory?.name} products delivered with Shipt. We have thousands of different ${pageCategory?.name} products that can be delivered to your door in as soon as an hour.`
}
