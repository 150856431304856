// This file maps
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable shipt/no-hardcoded-routes */

export const ungatedCategoryMetaDescriptions = [
  {
    path: '/categories/alcohol/beer/non-alcoholic',
    description:
      'Enjoy the convenience of same-day delivery on your favorite Non-Alcoholic Beers when you order via Shipt. Get your favorite products delivered instantly!',
  },
  {
    path: '/categories/alcohol/spirits',
    description:
      'Browse a wide selection of premium Spirits and have them delivered the same day via Shipt. Find your favorite brands and discover new ones today!',
  },
  {
    path: '/categories/alcohol/spirits/vodka',
    description:
      'Our premium Vodka selection is perfect for cocktails and sipping, and can be delivered the same day via Shipt. Find the perfect bottle for any occasion today!',
  },
  {
    path: '/categories/alcohol/wine/specialty-and-flavored',
    description:
      'You can find something sweet or savory in our Specialty and Flavored Wine selection and have it delivered the same day. Find your new favorite today!',
  },
  {
    path: '/categories/alcohol/hard-seltzer-and-more/cider',
    description:
      'Whether you prefer a crisp, dry cider or a fruity, sweet one, find your favorite Hard Cider or try a new one. Order now & have Hard Cider delivered the same day!',
  },
  {
    path: '/categories/alcohol/spirits/rum',
    description:
      'From light & spiced to dark & aged, shop our wide collection of Rum and have it delivered the same day via Shipt. Shop online now!',
  },
  {
    path: '/categories/alcohol/spirits/cordials-and-liqueurs',
    description:
      'Enhance your cocktails with our diverse selection of Cordials and Liqueurs. Shop online and enjoy same-day delivery via Shipt!',
  },
  {
    path: '/categories/alcohol/beer/craft',
    description:
      'Discover our wide selection of IPAs, stouts, sours, and more, and have your favorite Craft Beer delivered the same day via Shipt. Order online now!',
  },
  {
    path: '/categories/alcohol/hard-seltzer-and-more/hard-soda-and-seltzer',
    description:
      'Order variety packs, unique flavors, and all your favorite Hard Seltzer brands and have them delivered the same day via Shipt. Shop online now!',
  },
  {
    path: '/categories/alcohol/hard-seltzer-and-more/malt-beverages',
    description:
      'Choose from a variety of refreshing Malt Beverages that are perfect for parties, BBQs, and more. Get them delivered to your door on the same day!',
  },
  {
    path: '/categories/alcohol/wine',
    description:
      'Choose from our selection of Red, White, Rosé Wines, & more, & let us transport your taste buds. Shop online now & get Wine delivered today via Shipt.',
  },
  {
    path: '/categories/alcohol/spirits/tequila',
    description:
      'From blanco to añejo, find your favorite Tequila brands and explore new distilleries. Shop online now and get Tequila delivered same day!',
  },
  {
    path: '/categories/alcohol/wine/white',
    description:
      'From Sauvignon Blanc to Chardonnay, find the perfect White Wine for any occasion. Shop now & get your order delivered to your door in as soon as an hour via Shipt.',
  },
  {
    path: '/categories/alcohol/spirits/whiskey',
    description:
      'From smoky Scotch to bold Bourbon, explore our extensive selection of Whisky and enjoy same-day delivery for your online orders via Shipt.',
  },
  {
    path: '/categories/alcohol/wine/rose-and-blushes',
    description:
      'Shop our range of light, fruity, and refreshing Rosé & Blush Wines and find your perfect shade. Enjoy same-day delivery when you order now via Shipt.',
  },
  {
    path: '/categories/alcohol/wine/red',
    description:
      'Explore our selection of Red Wines, from Zinfandel to Pinot Noir, and discover your new favorite. Shop online today for same-day delivery via Shipt.',
  },
  {
    path: '/categories/alcohol/hard-seltzer-and-more/prepared-cocktails',
    description:
      'Explore our range of Prepared Cocktails and enjoy them without the hassle of mixing. Order online today for same-day delivery in as little as one hour via Shipt.',
  },
  {
    path: '/categories/alcohol/beer',
    description:
      'Explore our range of Craft, Domestic, Imported, and Non-Alcoholic Beers to find your perfect brew. Order online now for same-day delivery via Shipt.',
  },
  {
    path: '/categories/alcohol/hard-seltzer-and-more',
    description:
      'Discover our variety of Hard Seltzers and Prepared Cocktails, perfect for parties, picnics, and more. Order online now for same-day delivery via Shipt.',
  },
  {
    path: '/categories/alcohol/wine/champagne-and-sparkling',
    description:
      'Choose the perfect Champagne & Sparkling Wine for appetizers, desserts, celebrations, & more, and have them delivered the same day via Shipt. Shop online now!',
  },
  {
    path: '/categories/alcohol/beer/domestic',
    description:
      "America's finest beers, delivered right to your door! Browse our selection of Domestic Beers & enjoy fast delivery via Shipt.",
  },
  {
    path: '/categories/alcohol',
    description:
      'Your one-stop shop for all things alcohol. Wine, Beer, Hard Seltzers, Spirits, and more, delivered to your door via Shipt.',
  },
  {
    path: '/categories/alcohol/beer/imported',
    description:
      'Browse our collection of Imported Beers from Germany, Japan, Mexico, and more. Discover unique international flavors and enjoy same-day delivery through Shipt.',
  },
  {
    path: '/categories/deli',
    description:
      'Browse our selection of deli meats, dips, & cheeses for sandwiches, snacks, & parties. Get your Deli products delivered the same day via Shipt today!',
  },
  {
    path: '/categories/breads-and-bakery',
    description:
      'Shop our bakery online and find your favorite bread, pastries, cakes, and more. Enjoy freshly baked goodness delivered right to your door via Shipt.',
  },
  {
    path: '/categories/beauty',
    description:
      'Our wide selection of makeup, skincare, haircare, & more will help you look & feel your best. Shop now & get your Beauty products delivered the same day via Shipt.',
  },
  {
    path: '/categories/household-essentials',
    description:
      'Stock up on household essentials without leaving home. Order all your household essentials in one place and get them delivered the same day via Shipt.',
  },
  {
    path: '/categories/pets',
    description:
      'Explore our selection of pet products, including food, toys, grooming supplies, & more. Shop online now & get your Pet products delivered the same day via Shipt.',
  },
  {
    path: '/categories/baby-and-toddler',
    description:
      'From diapers to formula, we have everything you need. Shop online today for all your Baby & Toddler essentials & get them delivered the same day through Shipt.',
  },
  {
    path: '/categories/international',
    description:
      'Find ingredients for your next international dinner. Browse our selection of salsa, sriracha, noodles, & more, & get them delivered the same day via Shipt.',
  },
  {
    path: '/categories/kitchen-and-dining',
    description:
      'Looking to upgrade your cookware or find the perfect serving dish? Shop our kitchen and dining essentials, and enjoy same-day delivery via Shipt.',
  },
  {
    path: '/categories/patio-and-garden',
    description:
      'Shop garden tools, grilling essentials, & more. Discover everything you need for your outdoor space & enjoy same-day delivery of your favorite products via Shipt.',
  },
  {
    path: '/categories/entertainment',
    description:
      'Find the perfect entertainment for any occasion. Shop books, movies, & more. Enjoy same-day delivery on all products when you order now!',
  },
  {
    path: '/categories/electronics',
    description:
      'Find all the electronics you need in one place. Shop smartphones, batteries, headphones, and more. Shop online today and enjoy same-day delivery with Shipt.',
  },
  {
    path: '/categories/toys',
    description:
      'Shop dolls, action figures, board games, card games, & more. Find the perfect toy and games for kids of all ages. Shop now & enjoy same-day delivery with Shipt.',
  },
  {
    path: '/categories/mens-and-womens-apparel',
    description:
      "Find everything you need for your wardrobe, from socks to shirts. Shop our range of men's & women's apparel & get your products delivered the same day via Shipt.",
  },
  {
    path: '/categories/kids-apparel',
    description:
      'Find the perfect outfit for every occasion. Shop our selection of kids apparel and accessories, available for same-day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy',
    description:
      "From classic sweets to gourmet snacks, we've got the perfect treat for every craving. Shop now & get your favorite Snacks & Candy products delivered with Shipt.",
  },
  {
    path: '/categories/snacks-and-candy/chips',
    description:
      'Shop potato chips, tortilla chips, veggie chips, & more. Order your favorite Chips today and get same-day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/crackers',
    description:
      'Shop cheese crackers, wheat crackers, multigrain crackers, and more. Shop our selection of Crackers today and enjoy same day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/candy-and-chocolate',
    description:
      'Craving something sweet? Shop our selection of chocolate & candy, from rich, decadent bites to fruity, chewy treats and get same-day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/cookies-and-snack-cakes',
    description:
      'Shop our cookies and snack cakes for parties, gatherings, or just because. Order your favorites today and get same day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/fruit-and-vegetable-snacks',
    description:
      'Discover fruit and vegetable snacks perfect for healthy, on-the-go munching. Order your fruit and vegetable snacks now and enjoy same-day delivery with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/granola-and-nutrition-bars',
    description:
      'Shop granola bars, protein bars, breakfast bars, & more. Find your perfect energy boost and get your Granola & Nutrition Bar products delivered with Shipt.',
  },
  {
    path: '/categories/snacks-and-candy/nuts-and-seeds',
    description:
      'Shop for almonds, cashews, pistachios, sunflower seeds, & more. Discover your ideal healthy nut and seed snacks and have them delivered the same day with Shipt.',
  },
]
